<template>
  <div v-loading="daochuexcel">
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>商城订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add" style="line-height: 40px">
      <!-- <el-input
        v-model="mallID"
        placeholder="输入商户ID"
        clearable
        disabled
        @clear="mallID = ''"
        style="width:150px;margin-right:20px;"
      /> -->
      <el-select v-model="orderstate" placeholder="请选择订单状态" style="width: 150px; margin-right: 10px" clearable @clear="orderstate = ''" size="small">
        <el-option v-for="item in options1" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="type" placeholder="请选择订单类型" style="width: 150px; margin-right: 10px" clearable @clear="type = ''" size="small">
        <el-option v-for="item in typeoptions1" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker size="small" v-model="date" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable style="width: 260px; margin-right: 10px">
      </el-date-picker>
      <el-input size="small" v-model="tel" placeholder="收件人的手机号或姓名" clearable @clear="tel = ''" style="width: 180px; margin-right: 10px" />
      <el-input size="small" v-model="regTel" placeholder="注册手机号" clearable @clear="regTel = ''" style="width: 150px; margin-right: 10px" />
      <el-input size="small" v-model="orderid" placeholder="订单号或流水号或快递单号" clearable @clear="orderid = ''" style="width: 260px; margin-right: 10px" />
      <el-button size="small" @click="query()" type="primary">查询</el-button>
      <el-button size="small" type="primary" @click="exportorder">导出Excel</el-button>
    </div>
    <p style="color: red; text-align: left; margin: 0 0 10px 0">
      说明：待发货日期按照支付时间查询；已发货日期按照发货时间查询；退款日期按照退款申请时间查询；
    </p>
    <el-table :data="list" border style="width: 100%" v-loading="loading" element-loading-text="数据加载中，请稍后...">
      <el-table-column label="注册电话" prop="regTel" width="110">
      </el-table-column>
      <el-table-column label="订单编号" prop="orderId" width="200">
        <template slot-scope="scope">
          <div>
            {{ scope.row.orderId
            }}<span v-if="scope.row.isGive == 1" style="font-size: 10px; color: green">（赠送订单）</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="流水单号" prop="billNo" width="150">
      </el-table-column>
      <el-table-column label="客户编号" prop="extendId" width="140">
        <template slot-scope="scope">
          {{ filterextendId(scope.row.extendId) }}
        </template>
      </el-table-column>
      <el-table-column label="收件人" prop="receiver" width="100"></el-table-column>
      <el-table-column label="电话" prop="tel" width="110"></el-table-column>
      <el-table-column label="金额" prop="total" width="100">
        <template slot-scope="scope">
          ￥{{ getFlorstr(scope.row.total) }}
        </template>
      </el-table-column>
      <el-table-column label="地址" width="250">
        <template slot-scope="scope">
          {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.distict
          }}{{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="createTime" width="200">
        <template slot-scope="scope">
          <div style="font-size: 10px">
            创建时间:{{ filtertime(scope.row.createTime) }}
          </div>
          <div style="font-size: 10px">
            支付时间:{{ filtertime(scope.row.payTime) }}
          </div>
          <div style="font-size: 10px" v-if="scope.row.sendTime">
            发货时间:{{ filtertime(scope.row.sendTime) }}
          </div>
          <div style="font-size: 10px" v-if="scope.row.requstTime">
            退款时间:{{ filtertime(scope.row.requstTime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" prop="status" width="170">
        <template slot-scope="scope">
          {{ ordertype(scope.row.status) }}
          <br />
          <span v-if="scope.row.expressNo" style="font-size: 10px">运单号:{{ scope.row.expressNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付类型" prop="payType" width="150">
        <template slot-scope="scope">
          {{ paytype(scope.row.payType) }}
        </template>
      </el-table-column>
      <el-table-column width="200" fixed="right">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button size="small" type="primary" v-if="isau(scope.row)" @click="lookgiveorder(scope.row)">查看赠送订单</el-button>
              <el-button size="small" type="info" @click="lookordermore(scope.row)">订单详情</el-button>
            </p>
            <p>
              <el-button size="small" type="warning" v-if="scope.row.status == 1" @click="editadress(scope.row)">修改地址</el-button>
              <el-button size="small" type="success" v-if="isau(scope.row) && scope.row.status == 1" @click="delivergoods(scope.row)">中通发货</el-button>
            </p>
            <p>
              <el-button size="small" type="warning" v-if="scope.row.status == 9" @click="looktkxq(scope.row)">查看售后详情</el-button>
              <el-button size="small" type="primary" v-if="scope.row.status == 1 || scope.row.status == 2" @click="pmfh(scope.row)">录入单号</el-button>
            </p>
            <p>
              <!-- <el-button size="mini" type="danger" v-if="scope.row.status == 1" @click="zjtk(scope.row)">直接退款</el-button> -->
              <el-button size="small" type="danger" v-if="scope.row.status == 1" @click="jstk(scope.row)">急速退款</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
      </el-pagination>
    </p>

    <el-dialog title="查看赠送订单" :visible.sync="dialogFormVisible" width="80%">
      <el-table :data="freelist" border style="width: 100%">
        <el-table-column label="订单编号" prop="orderId"></el-table-column>
        <!-- <el-table-column label="客户编号" prop="extendId"></el-table-column> -->
        <el-table-column label="收件人" prop="receiver"></el-table-column>
        <el-table-column label="电话" prop="tel"></el-table-column>
        <el-table-column label="金额" prop="total">
          <template slot-scope="scope">
            ￥{{ getFlorstr(scope.row.total) }}
          </template>
        </el-table-column>
        <el-table-column label="地址">
          <template slot-scope="scope">
            {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.distict
            }}{{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template slot-scope="scope">
            {{ filtertime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="支付时间" prop="payTime">
          <template slot-scope="scope">
            {{ filtertime(scope.row.payTime) }}
          </template>
        </el-table-column>
        <el-table-column label="发货时间" prop="payTime">
          <template slot-scope="scope">
            {{ filtertime(scope.row.sendTime) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" prop="status">
          <template slot-scope="scope">
            {{ ordertype(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column label="支付类型" prop="payType">
          <template slot-scope="scope">
            {{ paytype(scope.row.payType) }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>

    <el-dialog title="查看售后详情" :visible.sync="dialogFormVisible1">
      <div style="text-align: left">
        <p>申请时间：{{ requstTime }}</p>
        <p>退款原因:{{ reason }}</p>
        <el-image v-for="(item, index) in piclist" :key="index" :src="item"></el-image>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="istuikuan('agree')">同 意</el-button>
        <el-button type="danger" @click="istuikuan('no')">拒 绝</el-button>
      </div>
    </el-dialog>

    <el-dialog title="录入快递单号" :visible.sync="paimaifahuo">
      <div style="text-align: center">
        <el-input v-model="kuaididanhao" placeholder="请输入快递单号" size="small" clearable @clear="kuaididanhao = ''" style="width: 50%" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(paimaifahuo = false), (kuaididanhao = '')" size="small">取 消</el-button>
        <el-button type="primary" @click="agreefahuo" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改地址" :visible.sync="changeaddress">
      <el-form ref="addressfrom" :rules="rules" :model="addressfrom" label-width="100px">
        <el-form-item label="订单号">
          <el-input v-model="addressfrom.orderId" disabled size="small"></el-input>
        </el-form-item>
        <el-form-item label="收件人" prop="receiver">
          <el-input v-model="addressfrom.receiver" size="small"></el-input>
        </el-form-item>
        <el-form-item label="收件人电话" prop="tel">
          <el-input v-model="addressfrom.tel" size="small"></el-input>
        </el-form-item>
        <el-form-item label="省/市/区" prop="province">
          <el-row :gutter="12">
            <el-col :span="6">
              <el-form-item prop="province">
                <el-input v-model="addressfrom.province" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="city">
                <el-input v-model="addressfrom.city" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="distict">
                <el-input v-model="addressfrom.distict" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="addressfrom.address" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeaddress = false" size="small">取 消</el-button>
        <el-button type="primary" @click="surechangeaddress('addressfrom')" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看订单详情" :visible.sync="lookorderMore">
      <el-table :data="orderdetail" border style="width: 100%" row-key="key" :tree-props="{children: 'subGoodsList'}">
        <el-table-column label="产品名" prop="goodsName">
          <template slot-scope="scope">
            <span>{{scope.row.goodsName}}</span>
            <span style="color:green;float: right;font-size: 10px;">{{filtertitle(scope.row)}}</span>
          </template>
        </el-table-column>
        <!-- 如果是打包商品，显示查看打包商品详情 -->
        <el-table-column label="规格" prop="standardName"></el-table-column>
        <el-table-column label="数量" prop="num"></el-table-column>
        <el-table-column label="价格" prop="price">
          <template slot-scope="scope">
            <span> ￥{{ scope.row.price }}</span>
            <span style="float: right;font-size: 10px;color: orange;">{{filterprice(scope.row)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" v-if="fahuobtn" @click="fahuo">中通发货</el-button>
        <el-button size="small" v-if="!fahuobtn" @click="lookorderMore = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-table :data="tableData" border style="width: 100%" id="tableBox" v-show="false">
      <el-table-column label="订单编号" prop="orderId">
        <template slot-scope="scope">
          <div>
            {{ scope.row.orderId }}
            <span v-if="scope.row.isGive == 1" style="font-size: 10px; color: green">（赠送订单）</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="流水单号" prop="billNo"></el-table-column>
      <el-table-column label="客户编号" prop="extendId">
        <template slot-scope="scope">
          {{ filterextendId(scope.row.extendId) }}
        </template>
      </el-table-column>
      <el-table-column label="收件人" prop="receiver"></el-table-column>
      <el-table-column label="电话" prop="tel"></el-table-column>
      <el-table-column label="金额" prop="total">
        <template slot-scope="scope">
          ￥{{ getFlorstr(scope.row.total) }}
        </template>
      </el-table-column>
      <el-table-column label="地址">
        <template slot-scope="scope">
          {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.distict
          }}{{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column label="订单详情" prop="orderDetails">
        <template slot-scope="scope">
          {{ filterpro(scope.row.orderDetails) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime">
        <template slot-scope="scope">
          {{ filtertime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="支付时间" prop="payTime">
        <template slot-scope="scope">
          {{ filtertime(scope.row.payTime) }}
        </template>
      </el-table-column>
      <el-table-column label="发货时间" prop="payTime">
        <template slot-scope="scope">
          {{ filtertime(scope.row.sendTime) }}
        </template>
      </el-table-column>
      <el-table-column label="退款时间" prop="payTime">
        <template slot-scope="scope">
          {{ filtertime(scope.row.requstTime) }}
        </template>
      </el-table-column>
      <el-table-column label="订单状态" prop="status">
        <template slot-scope="scope">
          {{ ordertype(scope.row.status) }}
          <br />
          <span v-if="scope.row.expressNo" style="font-size: 10px">订单号:{{ scope.row.expressNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付类型" prop="payType">
        <template slot-scope="scope">
          {{ paytype(scope.row.payType) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  data() {
    return {
      mallID: "4",
      orderstate: "",
      options1: [
        { id: 0, value: "创建订单" },
        { id: 1, value: "待发货订单" },
        { id: 2, value: "已发货订单" },
        { id: 3, value: "确认收货订单" },
        { id: 4, value: "评价" },
        { id: 5, value: "追评" },
        { id: 9, value: "申请售后" },
        { id: 10, value: "退款" },
        { id: 11, value: "换货" },
        { id: 12, value: "拒绝维权请求" },
      ],
      date: [],
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      paystate: [
        { id: 0, value: "微信支付" },
        { id: 1, value: "支付宝支付" },
        { id: 3, value: "易飞微信支付" },
        { id: 4, value: "易飞支付宝支付" },
        { id: 5, value: "线下支付" },
      ],
      reason: "",
      piclist: [],
      tuikuanrow: "",
      freelist: [],
      loading: false,
      tableData: [],
      tel: "", //手机号
      type: "",
      typeoptions1: [
        { id: "0", value: "正常订单" },
        { id: "1", value: "拍卖订单" },
      ],
      paimaifahuo: false,
      paimairow: "",
      kuaididanhao: "",
      orderid: "",
      changeaddress: false,
      addressfrom: {
        orderId: "",
        tel: "",
        receiver: "",
        province: "",
        city: "",
        distict: "",
        address: "",
      },
      rules: {
        receiver: [
          {
            required: true,
            message: "请输入收件人",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入收件人电话",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        province: [{ required: true, message: "请输入省", trigger: "blur" }],
        city: [{ required: true, message: "请输入市", trigger: "blur" }],
        distict: [{ required: true, message: "请输入区", trigger: "blur" }],
      },
      lookorderMore: false,
      orderdetail: [],
      daochuexcel: false,
      requstTime: "",
      regTel: "",
      delivergoodsmsg: "",
      fahuobtn: false,
    };
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate == null) {
        this.date = [];
      }
    },
    regTel(n, o) {
      if (n != "") {
        this.tel = "";
      }
    },
    tel(n, o) {
      if (n != "") {
        this.regTel = "";
      }
    },
  },
  mounted() {},
  methods: {
    isau(row) {
      if (row.orderId.substr(0, 2) == "AU") {
        return false;
      } else {
        return true;
      }
    },
    pmfh(row) {
      this.paimaifahuo = true;
      this.paimairow = row;
    },
    agreefahuo() {
      // this.loading = true;
      var form = {
        orderId: this.paimairow.orderId,
        expressNo: this.kuaididanhao,
      };
      this.axios
        .post(
          this.serveurl.mallserve + "/api/admin/ProcessOrderEx/order_send",
          this.qs.stringify(form)
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: "发货成功",
            });
            this.getlist(1);
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
          // this.loading = false;
          this.paimaifahuo = false;
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getlist(currentPage);
    },
    getlist(page) {
      var str = "";
      if (this.mallID != "") {
        str += "&shopId=" + this.mallID;
      }
      if (this.orderstate !== "") {
        str += "&status=" + this.orderstate;
      } else {
        //不选择状态的时候
        str += "&status=-1";
      }
      if (this.type !== "") {
        str += "&type=" + this.type;
      } else {
        //不选择订单类型的时候
        str += "&type=-1";
      }
      if (this.tel != "") {
        str += "&tel=" + this.tel;
      }
      if (this.orderid != "") {
        str += "&orderid=" + this.orderid;
      }
      // if (this.tel === "" && this.orderid === "") {
      // console.log('tel weikong ')
      // if (this.orderstate !== "") {
      //   str += "&status=" + this.orderstate;
      // } else {
      //   this.$message({
      //     type: "error",
      //     message: "请选择订单状态",
      //   });
      //   return;
      // }
      // if (this.type !== "") {
      //   str += "&type=" + this.type;
      // } else {
      //   this.$message({
      //     type: "error",
      //     message: "请选择订单类型",
      //   });
      //   return;
      // }
      // }else{
      //   console.log('tel buweikong')
      // }
      if (this.date.length > 0) {
        str += "&start=" + this.date[0];
        str += "&end=" + this.date[1];
      }
      if (this.regTel != "") {
        str += "&regTel=" + this.regTel;
      }
      this.loading = true;
      this.axios
        .get(
          // this.serveurl.mallserve +
          //   "/api/admin/ProcessOrderEx/lookup_all?index=" +
          this.serveurl.mallserve1 +
            "/api/admin/Order/lookup_all?index=" +
            page +
            "&pagesize=" +
            this.PageSize +
            str
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.totalCount = res.data.data.count;
            this.list = res.data.data.orderList;
          } else {
            this.totalCount = 0;
            this.list = [];
          }
          this.loading = false;
        });
    },
    filtertime(val) {
      if (val) {
        return val.replace(/\//g, "-");
      }
    },
    query() {
      this.getlist(1);
    },
    getFlorstr(num) {
      num += "";
      num = num.replace(/[^0-9|\.]/g, ""); //清除字符串中的非数字非.字符
      if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, "");
      if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += ".00";
      if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = "0" + num;
      num += "00"; //在字符串末尾补零
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    paytype(val) {
      var str = "";
      for (let a = 0; a < this.paystate.length; a++) {
        if (val == this.paystate[a].id) {
          str = this.paystate[a].value;
        }
      }
      return str;
    },
    ordertype(val) {
      var str = "";
      for (let a = 0; a < this.options1.length; a++) {
        if (val == this.options1[a].id) {
          str = this.options1[a].value;
        }
      }
      return str;
    },
    filterextendId(val) {
      var str = "";
      if (val == "" || val == null || val == "null") {
        str = "暂无";
      } else {
        str = val;
      }
      return str;
    },
    filterpro(row) {
      var str = "";
      for (let i = 0; i < row.length; i++) {
        str += row[i].goodsName + row[i].standardName + ",";
      }
      return str;
    },
    //查看赠送订单
    lookgiveorder(row) {
      this.dialogFormVisible = true;
      this.axios
        .get(
          this.serveurl.mallserve +
            "/api/admin/ProcessOrderEx/get_give_order_list?orderId=" +
            row.orderId
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.freelist = res.data.data;
          }
        });
    },
    //发货
    delivergoods(row) {
      // this.lookorderMore = true;
      this.lookordermore(row)
      this.delivergoodsmsg = row;
      this.fahuobtn = true;
    },
    fahuo() {
      this.$confirm("确认发货吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.loading = true;
          this.axios
            .post(
              this.serveurl.mallserve +
                "/api/admin/ProcessOrderEx/zto_express_send_request",
              this.qs.stringify({ orderId: this.delivergoodsmsg.orderId })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getlist(1);
              } else if (res.data.code == 0) {
                this.$message({
                  type: "error",
                  message: "此单异常",
                });
              } else if (res.data.code == 2) {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //查看退款详情
    looktkxq(row) {
      this.dialogFormVisible1 = true;
      this.tuikuanrow = row;
      this.axios
        .get(
          this.serveurl.mallserve +
            "/api/admin/PostSale/get_postsale?orderId=" +
            row.orderId
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.requstTime = res.data.data.postSale.requstTime;
            this.reason = res.data.data.postSale.reason;
            this.piclist = JSON.parse(res.data.data.postSale.images);
          }
        });
    },
    istuikuan(type) {
      var stste = "";
      if (type == "agree") {
        stste = 1;
      } else {
        stste = 0;
      }
      this.$confirm("确认该操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              this.serveurl.mallserve + "/api/admin/PostSale/process_postsale",
              this.qs.stringify({
                orderId: this.tuikuanrow.orderId,
                isAgree: stste,
              })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "退款正在处理",
                });
                this.getlist(1);
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
          this.dialogFormVisible1 = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行下载
    exportorder() {
      this.daochuexcel = true;
      var str = "";
      if (this.mallID != "") {
        str += "&shopId=" + this.mallID;
      }
      if (this.orderstate !== "") {
        str += "&status=" + this.orderstate;
      } else {
        str += "&status=-1";
      }
      //  else {
      //   this.$message({
      //     type: "error",
      //     message: "请选择订单状态",
      //   });
      //   return;
      // }
      if (this.type !== "") {
        str += "&type=" + this.type;
      } else {
        str += "&type=-1";
      }
      // else {
      //   this.$message({
      //     type: "error",
      //     message: "请选择订单类型",
      //   });
      //   return;
      // }
      if (this.tel != "") {
        str += "&tel=" + this.tel;
      }
      if (this.orderid != "") {
        str += "&orderid=" + this.orderid;
      }
      if (this.date.length > 0) {
        str += "&start=" + this.date[0];
        str += "&end=" + this.date[1];
      }
      if (this.regTel != "") {
        str += "&regTel=" + this.regTel;
      }
      var dom = document.createElement("a");
      //下载文件的路径
      dom.href =
        this.serveurl.mallserve1 +
        "/api/admin/Order/export_lookup_all?index=1&pagesize=20" +
        str;
      //对下载的文件命名
      dom.download = "";
      this.daochuexcel = false;
      dom.click();
    },
    // async exportorder() {
    //   this.daochuexcel = true;
    //   await this.setData();
    //   this.exportOut();
    // },
    // setData: async function () {
    //   this.arr = [];
    //   this.tableData = [];
    //   let num = Math.ceil(this.totalCount / this.PageSize);
    //   let m = "";
    //   let a = [];
    //   for (m = 1; m <= num; m++) {
    //     a = await this.funA(m);
    //     this.arr = this.arr.concat(a.data.data.orderList);
    //   }
    //   this.tableData = this.arr;
    // },
    // funA: async function (m) {
    //   var str = "";
    //   if (this.date.length > 0) {
    //     str += "&start=" + this.date[0];
    //     str += "&end=" + this.date[1];
    //   }
    //   if (this.mallID != "") {
    //     str += "&shopId=" + this.mallID;
    //   }
    //   if (this.orderstate != "") {
    //     str += "&status=" + this.orderstate;
    //   }
    //   if (this.type != "") {
    //     str += "&type=" + this.type;
    //   }
    //   return await this.axios.get(
    //     this.serveurl.mallserve +
    //       "/api/admin/ProcessOrderEx/lookup_all?index=" +
    //       m +
    //       "&pagesize=" +
    //       this.PageSize +
    //       str
    //   );
    // },
    //下载表格
    exportOut() {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#tableBox"),
        xlsxParam
      );
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.daochuexcel = false;
      return wbout;
    },
    //修改地址
    editadress(row) {
      this.addressfrom.selectedOptions = [];
      this.changeaddress = true;
      this.addressfrom.orderId = row.orderId;
      this.addressfrom.tel = row.tel;
      this.addressfrom.receiver = row.receiver;
      this.addressfrom.address = row.address;
      this.addressfrom.province = row.province;
      this.addressfrom.city = row.city;
      this.addressfrom.distict = row.distict;
    },
    //确定修改地址
    surechangeaddress(formName) {
      var from = {
        orderId: this.addressfrom.orderId,
        tel: this.addressfrom.tel,
        receiver: this.addressfrom.receiver,
        province: this.addressfrom.province,
        city: this.addressfrom.city,
        distict: this.addressfrom.distict,
        address: this.addressfrom.address,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.serveurl.mallserve +
                "/api/admin/ProcessOrderEx/edit_order_address",
              this.qs.stringify(from)
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.changeaddress = false;
                this.query();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //查看订单详情
    lookordermore(row) {
      this.lookorderMore = true;
      this.fahuobtn = false;
      this.orderdetail = [];
      this.axios
        .get(
          this.serveurl.mallserve1 +
            "/api/admin/Order/get_order_detail?orderId=" +
            row.orderId
        )
        .then((res) => {
          if (res.data.result.code == 1) {
            this.orderdetail = res.data.result.data;
          } else {
          }
        });
    },
    //计算优惠价格
    filterprice(row) {
      if (row.subGoodsList) {
        var price = row.price * row.num;
        var price1 = "";
        for (let a = 0; a < row.subGoodsList.length; a++) {
          price1 += row.subGoodsList[a].price * row.subGoodsList[a].num;
        }
        var chajia = price1 - price;
        return "优惠￥" + chajia + "";
      } else {
        return "";
      }
    },
    filtertitle(row) {
      if (row.subGoodsList) {
        return "（组合商品）";
      } else {
        return "";
      }
    },
    //直接退款
    zjtk(row) {
      this.$confirm("是否确认直接退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              this.serveurl.mallserve + "/api/admin/PostSale/process_postsale",
              this.qs.stringify({ orderId: row.orderId, isAgree: 1 })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "退款正在处理",
                });
                this.query();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //极速退款
    jstk(row) {
      this.$confirm("一经退款后不能撤销，是否确认急速退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              this.serveurl.mallserve +
                "/api/admin/PostSale/process_postsale_no_request",
              this.qs.stringify({ orderId: row.orderId })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  type: "success",
                  message: "退款正在处理",
                });
                this.query();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 100px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt .el-button {
  margin-right: 8px;
  margin-bottom: 0;
  margin-left: 0;
  /* width: 60px; */
  display: inline-block;
  padding: 5px;
  font-size: 10px !important;
}
.opt p {
  margin: 0 0 8px 0;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
